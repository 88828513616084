<template>
    <v-sheet>
        <v-row class="mt-n5 align-baseline">
            <v-col class="title ml-3">기본정보</v-col>
            <v-col class="text-right">
                <v-btn outlined large rounded class="text-h6" color="grey" @click="openCheckPasswordPopup"><span style="color:#333333">정보변경</span></v-btn>
            </v-col>
        </v-row>
        <v-card flat class="rounded-xl mt-5">
            <v-card-text class="pl-10" style="background-color:#eeeeee; font-size:20px;">
                <v-row>
                    <v-col cols="3">아이디</v-col>
                    <v-col>{{adminUserInfo.admin_user_id}} <v-chip small color="green" outlined class="ml-3" v-if="adminUserInfo.main_admin_yn == 'Y'">대표관리자</v-chip></v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">비밀번호</v-col>
                    <v-col>******</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">관리자 휴대폰</v-col>
                    <v-col>{{adminUserInfo.admin_user_mdn | phoneNum}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">관리자 E-mail</v-col>
                    <v-col>{{adminUserInfo.admin_user_email}}</v-col>
                </v-row>
                <v-row class="mb-5">
                    <v-col cols="3">관리자 이름</v-col>
                    <v-col>{{adminUserInfo.admin_user_name}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">사업자등록번호</v-col>
                    <v-col>{{companyInfo.corp_reg_num | corpRegNum}}</v-col>
                </v-row>
                <v-row>
                    <v-col cols="3">업체명</v-col>
                    <v-col>{{companyInfo.company_name}}</v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="modal" persistent max-width="600" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'checkPassword'">
                <popup-check-password v-on:submit="doCheckPassword" v-on:cancel="closePopup"></popup-check-password>
            </v-flex>
        </v-dialog>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'admin_info',
    components: {
        'popupCheckPassword': () => import('@/components/admin/popup_check_password.vue'),
    },
    data: () => ({
        modal: false,
        dialogMode: 'none'

    }),
    created () {
    },
    computed: {
        ...mapGetters({
            adminUser: 'admin/getAdminUser',
            company: 'admin/getCompany',
        }),
        adminUserInfo () {
            if(this.adminUser) {
                return this.adminUser
            }
            return {
                admin_user_id: '',
                admin_user_name: '',
                admin_user_email: '',
                admin_user_mdn: '',
            }
        },
        companyInfo () {
            if(this.company) {
                return this.company
            }
            return {
                company_name: '',
                corp_reg_num: '',
                corp_reg_img_url: '',
            }
        }
    },
    methods: {
        goModify () {
            this.$emit('goModify')
        },
        openCheckPasswordPopup() { 
            this.dialogMode = 'checkPassword'
            this.modal = true
        },
        closePopup() { 
            this.modal = false
            this.dialogMode = 'none'
        },
        doCheckPassword () { 
            this.closePopup()
            this.goModify()
        }
    }
}
</script>
